.search-bar {
  margin: 0; }
  .search-bar,
  .search-bar * {
    font-weight: normal; }
  .search-bar .search-bar-input {
    background-color: white;
    border-radius: 4px; }
  .search-bar .search-bar-icon {
    padding-right: 1rem; }
  .search-bar .search-bar-icon::before {
    color: grey; }
  .search-bar .search-bar-input-focused .search-bar-icon::before {
    color: black; }
  .search-bar .search-bar-native-input {
    padding-top: 0.8rem;
    padding-bottom: 0.7rem; }
  .search-bar .search-bar-icon-clickable {
    padding-right: 1rem;
    cursor: pointer; }
    .search-bar .search-bar-icon-clickable::before {
      padding: 0.3rem;
      margin: -0.3rem;
      color: grey; }
    .search-bar .search-bar-icon-clickable:hover::before {
      color: black; }
