.review-wrapper {
  padding: 1rem;
  min-height: 100%; }

.review {
  padding: 1rem;
  background: #fff; }
  .review .review-list {
    margin-top: 15px;
    border-radius: 10px 10px 0px 0px; }
  .review .review-header {
    background-color: #46aea9;
    border-radius: 10px 10px 0px 0px; }
    .review .review-header h6 {
      color: #fff; }
  .review .back-link {
    color: #3c7a9e; }
  .review .review-empty {
    background-color: #fff;
    border-bottom: 1px solid #c9c9c9; }
    .review .review-empty p {
      color: #000;
      font-weight: bold;
      font-size: 16px;
      padding: 5px; }
  .review .review-item {
    background-color: #fff;
    border-bottom: 1px solid #c9c9c9; }
    .review .review-item p {
      display: inline-flex;
      color: #4b7293;
      padding: 5px 5px 5px 0px; }
      .review .review-item p sup {
        padding-right: 4px;
        margin-top: -4px; }
    .review .review-item .review-action {
      text-align: right; }
  .review .review-item:hover {
    background-color: #f5f5f5; }
  .review .header-badge span {
    top: 0;
    left: 0;
    margin-left: 0.5rem; }
  .review .review-badge {
    margin-left: 10px; }
    .review .review-badge span {
      margin-top: 6px;
      font-weight: bold; }
  .review .view-course-button {
    background-color: #138c21; }
    .review .view-course-button * {
      color: #fff; }
  .review .view-course-button:hover {
    background-color: #17a928 !important; }
