.form-page-container {
  padding: 1rem; }
  .form-page-container .form-item-container {
    padding: 1rem;
    margin-bottom: 1rem;
    background: white;
    border-top: 3px solid #d3d4d5; }
    .form-page-container .form-item-container .form-item {
      padding-bottom: 10px; }
    .form-page-container .form-item-container .change-password-button {
      padding: 4px 15px !important;
      background-color: #004882 !important;
      border-color: #006dc3 !important; }
    .form-page-container .form-item-container .change-password-button * {
      color: #fff !important; }
    .form-page-container .form-item-container .change-password-button:disabled {
      opacity: 0.25 !important; }
    .form-page-container .form-item-container .change-password-button:hover {
      background-color: #023b69 !important;
      border-color: #004882 !important; }
    .form-page-container .form-item-container .form-section-label {
      font-weight: bold;
      color: darkblue;
      padding-bottom: 10px; }
    .form-page-container .form-item-container .form-field {
      height: 40px; }
      .form-page-container .form-item-container .form-field input:disabled {
        cursor: not-allowed; }
