.contract {
  min-height: 350px;
  text-align: center;
  overflow: hidden !important;
  /* needed for slide animation */
  padding: 2rem 0; }
  .contract .support-link-row {
    padding: 1rem; }
  .contract .contract-title {
    margin-bottom: 1rem; }
  .contract .contract-button {
    margin-top: 0.5rem;
    width: 100%; }
  .contract .confirm-success {
    color: #14a214;
    padding-bottom: 1rem; }
    .contract .confirm-success i {
      width: 100%; }
  .contract .confirm-warning {
    color: #ff0000;
    padding-bottom: 1rem; }
    .contract .confirm-warning i {
      width: 100%; }
  .contract .back-button {
    position: absolute;
    left: 0;
    top: 35%;
    padding: 1.5rem; }
    .contract .back-button:hover {
      text-decoration: none !important; }
