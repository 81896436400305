.course-wrapper-root {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 0 0 4px 4px;
  -webkit-overflow-scrolling: touch; }
  .course-wrapper-root .cw-header {
    padding: 0.75rem;
    background-color: grey;
    border-radius: -1px -1px 0 0; }
    .course-wrapper-root .cw-header,
    .course-wrapper-root .cw-header * {
      color: white;
      line-height: 1.1; }
  .course-wrapper-root .cw-body {
    -webkit-flex: 1 1 0 !important;
    /* important for Safari */
    flex: 1 1 auto;
    padding: 0.75rem;
    border-radius: 0 0 3px 3px; }

.cw-overview {
  height: 100%; }

.cw-transcript {
  box-shadow: none;
  border: 1px solid grey; }
  .cw-transcript .cw-body {
    padding: 0; }

.cw-dashboard {
  height: 100%; }
  .cw-dashboard .cw-header {
    min-height: 3.7rem; }

.cw-dashboard-empty {
  height: 100%;
  background-color: #dadada; }
  .cw-dashboard-empty .cw-body * {
    color: #585858; }

.content-wrapper-root {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 1rem;
  -webkit-overflow-scrolling: touch; }
  .content-wrapper-root.no-padding {
    padding: 0; }

.content-wrapper-flat {
  border-radius: 0;
  box-shadow: none;
  border-top: 3px solid #d3d4d5;
  -webkit-overflow-scrolling: touch; }
