.loading-screen {
  height: 100%; }

.page-error {
  margin-top: 50px;
  text-align: center; }
  .page-error .bild-sad-cloud {
    width: 200px; }
  .page-error .bild-question-cloud {
    width: 200px; }
