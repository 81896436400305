@import url("https://use.typekit.net/pwm7sge.css");
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #e8ebec !important; }

#root {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch; }

/* ADDITIONAL FONTS */
@font-face {
  font-family: "Heebo";
  src: url("assets/fonts/Heebo-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* CUSTOM BILD FONTS */
@font-face {
  font-family: "BILDCloud-App";
  src: url("assets/bfont/BILDCloud-App.otf") format("opentype"), url("assets/bfont/BILDCloud-App.woff") format("woff"), url("assets/bfont/BILDCloud-App.ttf") format("truetype"), url("assets/bfont/BILDCloud-App.eot?#iefix") format("embedded-opentype"), url("assets/bfont/BILDCloud-App.svg#BILDCloud-App") format("svg");
  font-weight: normal;
  font-style: normal; }

.bfont {
  font-family: "BILDCloud-App";
  font-size: 1.6rem;
  font-style: normal; }

.bf-ip:after {
  content: "\f700" !important; }

.bf-equip:after {
  content: "\f701" !important; }

.bf-fire-1:after {
  content: "\f702" !important; }

.bf-fire-2:after {
  content: "\f703" !important; }

.bf-fire-3:after {
  content: "\f704" !important; }

.bf-fire-4:after {
  content: "\f705" !important; }

/* BILD CLOUD THEME COLORS */
html {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 100; }

.container {
  padding: 1rem; }
  @media (max-width: 960px) {
    .container {
      padding: 1rem; } }
  @media (max-width: 599px) {
    .container {
      padding: 1rem 0.5rem; } }

.scroll-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%; }

.page-headline {
  text-transform: capitalize !important;
  padding-left: 24px; }

.student-page-header {
  margin-bottom: 2rem; }
  .student-page-header .chevron {
    margin-right: 0.5rem; }
  @media (max-width: 800px) {
    .student-page-header {
      flex-direction: column;
      justify-content: center; }
      .student-page-header .logo *,
      .student-page-header .title * {
        text-align: center; } }

.link:hover {
  cursor: pointer;
  text-decoration: underline; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.default-link {
  text-decoration: underline !important;
  background-color: inherit !important;
  border-color: inherit !important; }
  .default-link:hover {
    text-decoration: none !important;
    cursor: pointer !important;
    opacity: 0.75 !important; }

.page-filter {
  width: 100%;
  line-height: 2;
  font-size: 18px;
  height: auto;
  padding-left: 20px;
  margin: 10px 0; }

.default-cancel-button {
  color: #000000de;
  padding: 4px 15px !important;
  background-color: #fff !important;
  border: 1px solid #858585 !important;
  border-radius: 4px !important;
  margin-right: 5px !important; }
  .default-cancel-button * {
    color: #a0a0a0 !important; }
  .default-cancel-button:hover {
    background-color: #d8d8d8 !important; }
    .default-cancel-button:hover * {
      color: #858585 !important; }

.default-submit-button {
  padding: 4px 15px !important;
  background-color: #006dc3 !important;
  border-color: #006dc3 !important;
  color: #fff !important; }
  .default-submit-button * {
    color: #fff !important;
    font-weight: 700; }
  .default-submit-button:disabled {
    opacity: 0.25 !important; }
  .default-submit-button:hover {
    background-color: #004882 !important;
    border-color: #004882 !important; }

.default-action-button {
  padding: 4px 15px !important;
  background-color: inherit !important;
  border: 1px solid #d8d8d8 !important; }
  .default-action-button * {
    color: inherit !important; }
    .default-action-button *:hover {
      cursor: pointer !important; }
  .default-action-button:disabled {
    opacity: 0.25 !important; }
  .default-action-button:hover {
    cursor: pointer !important;
    opacity: 0.75 !important;
    background-color: #ececec !important;
    text-decoration: none !important; }

.default-download-button {
  background-color: inherit !important;
  border-color: inherit !important; }
  .default-download-button * {
    color: inherit !important; }
    .default-download-button *:hover {
      cursor: pointer !important; }
  .default-download-button:disabled {
    opacity: 0.25 !important; }
  .default-download-button:hover {
    cursor: pointer !important;
    opacity: 0.75 !important;
    text-decoration: underline !important; }

.default-button {
  background-color: inherit !important;
  border-color: inherit !important; }
  .default-button * {
    color: inherit !important; }
    .default-button *:hover {
      cursor: pointer !important; }
  .default-button:disabled {
    opacity: 0.25 !important; }
  .default-button:hover {
    cursor: pointer !important;
    opacity: 0.75 !important;
    text-decoration: underline !important; }

.beta-menu-badge {
  border: 1px solid #aaa;
  border-radius: 4px;
  font-size: 0.75rem;
  color: #aaa !important;
  padding: 1px 4px;
  margin-left: 5px !important; }

.beta-badge {
  border: 1px solid #ff0000;
  border-radius: 4px;
  font-size: 0.75rem;
  color: #ff0000 !important;
  padding: 1px 4px;
  margin-left: 1rem !important; }

.menu-title-long {
  font-size: 0.98rem; }

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; }
