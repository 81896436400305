/***************
 ** Dashboard
 ***************/
.dashboard {
  padding: 1rem; }
  .dashboard .dashboard-programs {
    margin-bottom: 1rem; }
  .dashboard .dashboard-ep-header-root {
    padding: 1rem; }
    .dashboard .dashboard-ep-header-root .dashboard-ep-header-content {
      display: flex;
      flex-direction: column;
      margin: 0; }
  .dashboard .dashboard-ep-body {
    padding: 0; }
  .dashboard .dashboard-tab-container {
    padding: 0 1rem; }
  .dashboard .dashboard-tab-icon {
    display: inline-block;
    margin-bottom: -3px;
    margin-right: 8px; }

.dashboard-program-container {
  width: 100%; }
  .dashboard-program-container .expand-all-years-button {
    color: #000000de;
    font-size: 1rem;
    width: 100%;
    padding: 1rem;
    display: block;
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
    .dashboard-program-container .expand-all-years-button:hover * {
      color: #5f5f5f; }

.dashboard-pog-container {
  padding: 1rem;
  border-top: 1px solid lightgrey; }
  .dashboard-pog-container:first-child {
    border-top: none; }
  .dashboard-pog-container .dashboard-pog-title {
    text-transform: uppercase; }
  .dashboard-pog-container .dashboard-pog-course-group-header {
    min-height: 58px;
    padding: 0.75rem;
    border-radius: 1px 1px 0 0; }
    .dashboard-pog-container .dashboard-pog-course-group-header .dashboard-pog-course-group-header-text {
      line-height: 1.1em;
      color: white; }
  .dashboard-pog-container .dashboard-pog-course-group-body {
    padding: 0.75rem; }
  .dashboard-pog-container .dashboard-pog-course {
    cursor: pointer;
    margin-bottom: 0.5rem;
    color: black; }
    .dashboard-pog-container .dashboard-pog-course:hover {
      text-decoration: underline; }
    .dashboard-pog-container .dashboard-pog-course:last-child {
      margin-bottom: 0; }
    .dashboard-pog-container .dashboard-pog-course .dashboard-pog-course-icon {
      margin-right: 0.5rem;
      color: green; }
    .dashboard-pog-container .dashboard-pog-course .dashboard-pog-course-text {
      line-height: 1.1em; }

.dashboard-quick-actions {
  padding: 1rem;
  width: 100%; }
  .dashboard-quick-actions .dashboard-qa-tile {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%; }
    .dashboard-quick-actions .dashboard-qa-tile.upload-artifact {
      background-color: #ff733d; }
    .dashboard-quick-actions .dashboard-qa-tile.view-resource {
      background-color: #38a548; }
    .dashboard-quick-actions .dashboard-qa-tile.view-portfolio {
      background-color: #ec8700; }
    .dashboard-quick-actions .dashboard-qa-tile.implamentation-pathway {
      background-color: #e4aa17; }
      .dashboard-quick-actions .dashboard-qa-tile.implamentation-pathway .bfont {
        /* TODO remove this when we fix the bfont positioning */
        margin-top: -5px; }
    .dashboard-quick-actions .dashboard-qa-tile.equip-learner {
      background-color: #2a4491; }
    .dashboard-quick-actions .dashboard-qa-tile:hover {
      cursor: pointer; }
  .dashboard-quick-actions .dashboard-qa-grid-item-link.disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    user-select: none; }
    .dashboard-quick-actions .dashboard-qa-grid-item-link.disabled * {
      opacity: 0.5 !important; }
  .dashboard-quick-actions .dashboard-qa-title {
    color: #fff;
    padding: 0 0.2rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden; }
  .dashboard-quick-actions .dashboard-qa-icon {
    font-size: 64px;
    color: white;
    padding: 36px 0 20px 0; }
