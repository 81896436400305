.criterion-description-popover-container .criterion-description-popover {
  max-width: 350px;
  padding: 1rem; }

.rubric.v2 {
  margin-bottom: 1rem; }
  .rubric.v2 .rubric-section {
    margin-bottom: 1rem;
    width: 100%; }
  .rubric.v2 .rubric-table {
    border: 1px solid #bfbbbb;
    border-radius: 10px;
    overflow: hidden; }
    @media (max-width: 850px) {
      .rubric.v2 .rubric-table .rubric-title,
      .rubric.v2 .rubric-table .rubric-subtitle,
      .rubric.v2 .rubric-table .rubric-subtitle-info {
        font-size: 0.85rem !important; } }
    @media (max-width: 420px) {
      .rubric.v2 .rubric-table .rubric-title,
      .rubric.v2 .rubric-table .rubric-subtitle,
      .rubric.v2 .rubric-table .rubric-subtitle-info {
        font-size: 0.25rem !important; } }
    .rubric.v2 .rubric-table .rubric-subtitle {
      text-decoration: underline; }
      .rubric.v2 .rubric-table .rubric-subtitle:hover {
        text-decoration: none; }
    .rubric.v2 .rubric-table .rubric-subtitle-info {
      color: #616161;
      padding-left: 5px;
      margin-bottom: -3px; }
  .rubric.v2 .rubric-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .rubric.v2 input[type="radio"] {
    display: none; }
  .rubric.v2 .rubric-header-cell {
    border-left: 1px solid #bfbbbb;
    background-color: #e7e7e7;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .rubric.v2 .rubric-header-cell:first-child {
      border-left: none; }
    .rubric.v2 .rubric-header-cell h3 {
      line-height: 1.2em; }
    .rubric.v2 .rubric-header-cell .sub-header {
      display: flex;
      font-size: 0.9rem;
      font-weight: 400; }
      @media (max-width: 420px) {
        .rubric.v2 .rubric-header-cell .sub-header {
          font-size: 0.4rem !important; } }
  .rubric.v2 .rubric-criterion-name-cell {
    border-top: 1px solid #bfbbbb;
    background-color: #e7e7e7;
    padding: 1rem 1.5rem; }
    @media (max-width: 850px) {
      .rubric.v2 .rubric-criterion-name-cell {
        padding: 1rem 0.5rem; } }
    @media (max-width: 420px) {
      .rubric.v2 .rubric-criterion-name-cell {
        padding: 1rem 0.15rem; } }
  .rubric.v2 .rubric-cell {
    border-top: 1px solid #bfbbbb;
    border-left: 1px solid #bfbbbb;
    background-color: white;
    position: relative; }
    .rubric.v2 .rubric-cell label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 1.5rem 0.5;
      border: 1px solid transparent;
      /* Fix position of label on older browsers (safari/ios/android) */
      position: absolute;
      width: 100%;
      height: 100%; }
  .rubric.v2 .assessment-badges {
    text-align: center; }
    .rubric.v2 .assessment-badges .badge {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 1px 0 0 0;
      font-weight: bold;
      color: white;
      background-color: darkgrey;
      height: 32px;
      width: 32px;
      border: 2px solid #ffffff;
      border-radius: 50vh; }
    .rubric.v2 .assessment-badges .double-badge {
      width: 64px; }
    .rubric.v2 .assessment-badges .st-badges .badge {
      background-color: #eed69c; }
      .rubric.v2 .assessment-badges .st-badges .badge.current {
        background-color: #d59b1e; }
    .rubric.v2 .assessment-badges .cl-badges .badge {
      background-color: #c5c58e; }
      .rubric.v2 .assessment-badges .cl-badges .badge.current {
        background-color: #747401; }
    .rubric.v2 .assessment-badges .as-badges .badge,
    .rubric.v2 .assessment-badges .asr-badges .badge,
    .rubric.v2 .assessment-badges .af-badges .badge {
      background-color: #e3b2b3; }
      .rubric.v2 .assessment-badges .as-badges .badge.current,
      .rubric.v2 .assessment-badges .asr-badges .badge.current,
      .rubric.v2 .assessment-badges .af-badges .badge.current {
        background-color: #a91e1f; }
    .rubric.v2 .assessment-badges .aft-badges .badge {
      background-color: #fff;
      border: 2px dashed #000000;
      box-shadow: 0 0 10px #ff1919;
      color: #000; }
    .rubric.v2 .assessment-badges .afc-badges .badge {
      background-color: #fff;
      border: 2px dashed #000000;
      box-shadow: 0 0 10px #1997ff;
      color: #000; }
    .rubric.v2 .assessment-badges .badge-pile .badge {
      margin: 0.2rem -20px 0.2rem 0; }
      .rubric.v2 .assessment-badges .badge-pile .badge:last-child {
        margin-left: 0.2rem; }
      .rubric.v2 .assessment-badges .badge-pile .badge:last-child {
        margin-right: 0.2rem; }
    .rubric.v2 .assessment-badges .badge-pile .double-badge {
      margin: 0.2rem -50px 0.2rem 0; }
      .rubric.v2 .assessment-badges .badge-pile .double-badge:last-child {
        margin-left: 0.5rem; }
      .rubric.v2 .assessment-badges .badge-pile .double-badge:last-child {
        margin-right: 0.5rem; }

.rubric.v2 .fa-times {
  display: none; }

.rubric.v2 .rubric-cell:hover label:not([disabled]) {
  cursor: pointer;
  background: lightgrey; }

.rubric.v2 .rubric-cell.checked label {
  background: lightgrey;
  border: 1px solid lightgrey; }

.rubric.v2 .rubric-cell.checked .fa-times {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 2rem;
  width: 2rem;
  color: white;
  cursor: pointer;
  opacity: 0.8; }
  .rubric.v2 .rubric-cell.checked .fa-times:hover {
    color: #FFF;
    opacity: 1; }

.rubric.v2.three-grid .rubric-cell:hover label:not([disabled]) {
  cursor: pointer; }

.rubric.v2.three-grid .rubric-cell:hover.value1 label:not([disabled]) {
  background: #ed767380; }

.rubric.v2.three-grid .rubric-cell:hover.value3 label:not([disabled]) {
  background: #e6d76e80; }

.rubric.v2.three-grid .rubric-cell:hover.value4 label:not([disabled]) {
  background: #6dc08380; }

.rubric.v2.three-grid .rubric-cell.checked.value1 label {
  background: #ed7673 !important;
  border: 1px solid #ed7673; }

.rubric.v2.three-grid .rubric-cell.checked.value3 label {
  background: #e6d76e !important;
  border: 1px solid #e6d76e; }

.rubric.v2.three-grid .rubric-cell.checked.value4 label {
  background: #6dc083 !important;
  border: 1px solid #6dc083; }
