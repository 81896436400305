/***************
 ** Organizations
 ***************/
.admissions-organizations {
  padding: 10px; }
  .admissions-organizations .back-link {
    color: #3c7a9e; }
  .admissions-organizations .divider {
    margin: 1rem 0; }
  .admissions-organizations .admissions-table-header {
    background-color: #5490c4;
    border-radius: 10px 10px 0px 0px; }
    .admissions-organizations .admissions-table-header * {
      color: white; }
  .admissions-organizations .commendation-header {
    padding-left: 10px; }
  .admissions-organizations .admissions-commend {
    background: #14aaf5;
    padding: 10px 20px;
    margin: 10px 0px; }
    .admissions-organizations .admissions-commend p {
      color: #fff;
      font-weight: bold; }
    .admissions-organizations .admissions-commend:hover {
      background: #008ce3; }
  .admissions-organizations .admissions-item {
    background-color: #fff;
    border-bottom: 1px solid #c9c9c9; }
    .admissions-organizations .admissions-item:hover {
      background-color: #f5f5f5; }
    .admissions-organizations .admissions-item .admissions-panel {
      box-shadow: 0px 0px !important;
      background: transparent !important; }
      .admissions-organizations .admissions-item .admissions-panel .hidden {
        display: none; }
    .admissions-organizations .admissions-item .admissions-summary {
      cursor: default !important;
      user-select: text !important;
      padding: 0 0 0 0 !important; }
      .admissions-organizations .admissions-item .admissions-summary .admissions-line {
        padding: 0px !important; }
    .admissions-organizations .admissions-item .admissions-submenu {
      text-align: center; }
      .admissions-organizations .admissions-item .admissions-submenu button {
        color: #000; }
      .admissions-organizations .admissions-item .admissions-submenu .hidden {
        display: none; }
    .admissions-organizations .admissions-item .detail-item {
      text-align: center;
      padding: 2px; }
      .admissions-organizations .admissions-item .detail-item p {
        background: #999999;
        padding: 10px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center; }
      .admissions-organizations .admissions-item .detail-item.true p {
        background: #14a214; }
      .admissions-organizations .admissions-item .detail-item.true p:after {
        font-family: "Font Awesome 5 Pro";
        content: "\f00c";
        padding-left: 8px; }
      .admissions-organizations .admissions-item .detail-item.with-comment p {
        background: #d97b1c; }
      .admissions-organizations .admissions-item .detail-item.with-comment p:after {
        font-family: "Font Awesome 5 Pro";
        content: "\f071";
        padding-left: 8px; }
    .admissions-organizations .admissions-item .student,
    .admissions-organizations .admissions-item .email,
    .admissions-organizations .admissions-item .program {
      padding-right: 0.5rem; }
    .admissions-organizations .admissions-item .email * {
      color: grey; }
    .admissions-organizations .admissions-item .commended {
      color: #14a214;
      width: 100%; }
    .admissions-organizations .admissions-item .commendation-status {
      text-align: center; }
    .admissions-organizations .admissions-item .admissions-confirm {
      background: #ffa500;
      padding: 4px 16px;
      margin: 0px 5px; }
      .admissions-organizations .admissions-item .admissions-confirm p {
        color: #fff;
        font-weight: bold; }
      .admissions-organizations .admissions-item .admissions-confirm:hover {
        opacity: 0.9; }
    .admissions-organizations .admissions-item .admissions-resend p {
      color: #ff0000;
      border-bottom: 1px dotted #ff0000; }
    .admissions-organizations .admissions-item .admissions-resend:hover p {
      border-bottom: 1px solid transparent; }
    .admissions-organizations .admissions-item .status:hover {
      cursor: pointer;
      opacity: 0.9; }
    .admissions-organizations .admissions-item .status.with-comment:before {
      font-family: "Font Awesome 5 Pro";
      content: "\F071";
      padding-right: 10px;
      padding-top: 10px;
      float: left;
      color: #d97b1c;
      font-weight: bold; }
    .admissions-organizations .admissions-item .not-started {
      text-align: center;
      border: 1px solid #ff0000;
      border-radius: 76px;
      padding: 10px 5px; }
      .admissions-organizations .admissions-item .not-started span {
        color: #ff0000;
        font-weight: 700; }
    .admissions-organizations .admissions-item .progress {
      background-color: #c9ceea;
      padding: 10px 5px;
      text-align: center;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center; }
      .admissions-organizations .admissions-item .progress span {
        color: #fff;
        font-weight: bold; }
      .admissions-organizations .admissions-item .progress.size-12 {
        background: linear-gradient(to right, #5cb85c 100%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-11 {
        background: linear-gradient(to right, #5cb85c 90%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-10 {
        background: linear-gradient(to right, #5cb85c 80%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-9 {
        background: linear-gradient(to right, #5cb85c 72%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-8 {
        background: linear-gradient(to right, #5cb85c 64%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-7 {
        background: linear-gradient(to right, #5cb85c 56%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-6 {
        background: linear-gradient(to right, #5cb85c 48%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-5 {
        background: linear-gradient(to right, #5cb85c 40%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-4 {
        background: linear-gradient(to right, #5cb85c 32%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-3 {
        background: linear-gradient(to right, #5cb85c 24%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-2 {
        background: linear-gradient(to right, #5cb85c 16%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-1 {
        background: linear-gradient(to right, #5cb85c 8%, #999 0%); }
      .admissions-organizations .admissions-item .progress.size-0 {
        background: linear-gradient(to right, #5cb85c 0%, #999 0%); }
      .admissions-organizations .admissions-item .progress.admitted::after {
        width: 100%; }
      .admissions-organizations .admissions-item .progress.rejected::after {
        background-color: #ff0000;
        width: 100%; }
      .admissions-organizations .admissions-item .progress.unconfirmed::after {
        background-color: #ffa500; }
  .admissions-organizations .admissions-empty {
    background-color: #fff;
    border-bottom: 1px solid #c9c9c9; }
    .admissions-organizations .admissions-empty p {
      color: #000;
      font-weight: bold;
      font-size: 16px;
      padding: 5px; }
  .admissions-organizations .commend-user-fab-container {
    text-align: right;
    margin: 1rem 0.25rem; }

.dialog-field {
  padding: 0 10px; }

.admission-user-actions {
  display: block !important; }

.admissions-popper-button {
  color: #000;
  width: 100%;
  padding: 15px 10px !important; }
  .admissions-popper-button p {
    width: 100%;
    text-align: left; }
