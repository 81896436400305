/* Thanks to Eddit Lin - https://codepen.io/yshlin/pen/ylDEk */
.fireworks-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden; }

.firework-start,
.firework-end {
  position: absolute;
  width: 0.35rem;
  height: 0.35rem;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  opacity: 1; }

.firework-end {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s; }

@-webkit-keyframes bang {
  to {
    box-shadow: 41px 50.33333px #ff00c8, -141px -380.66667px #7bff00, 115px 77.33333px #bbff00, 12px -317.66667px #00ff40, 43px -110.66667px #3cff00, -64px -11.66667px #00ff37, 69px 16.33333px #00ff04, -36px -12.66667px #ff00ea, 235px -403.66667px #00bbff, -40px -368.66667px #8400ff, 159px 12.33333px #1e00ff, 124px -56.66667px #00ffb3, 50px -315.66667px #00ff73, 209px -375.66667px #ffd900, 190px -312.66667px #ff0084, 129px -214.66667px #37ff00, -23px -9.66667px #ff8400, 18px -336.66667px #000dff, -17px -289.66667px #3700ff, -50px 32.33333px #0033ff, 210px -20.66667px #ff2b00, 228px -10.66667px #51ff00, 45px -397.66667px #5500ff, 86px -388.66667px #ffbf00, 175px -269.66667px #d500ff, 234px 43.33333px #00ff15, -170px -96.66667px #0044ff, -55px -267.66667px #5e00ff, -92px -4.66667px #0080ff, 81px -35.66667px #b7ff00, 179px 22.33333px #d900ff, -248px -5.66667px #d5ff00, 210px -328.66667px #ffe600, 114px 13.33333px #6600ff, -19px -195.66667px #00eaff, -228px -78.66667px #9dff00, -112px -386.66667px #ff5100, 73px -59.66667px #3cff00, -66px 48.33333px #b7ff00, -126px -48.66667px #00ff66, -14px -100.66667px #00ff22, 37px -241.66667px #00ff40, 64px -29.66667px #00b7ff, -56px -204.66667px #0026ff, -127px -262.66667px #00ff8c, 10px -143.66667px #fff200, 104px -399.66667px #aa00ff, -155px -42.66667px #00d5ff, 44px -279.66667px #0077ff, 199px -212.66667px #b700ff, 131px -137.66667px #00d5ff; } }

@-moz-keyframes bang {
  to {
    box-shadow: 41px 50.33333px #ff00c8, -141px -380.66667px #7bff00, 115px 77.33333px #bbff00, 12px -317.66667px #00ff40, 43px -110.66667px #3cff00, -64px -11.66667px #00ff37, 69px 16.33333px #00ff04, -36px -12.66667px #ff00ea, 235px -403.66667px #00bbff, -40px -368.66667px #8400ff, 159px 12.33333px #1e00ff, 124px -56.66667px #00ffb3, 50px -315.66667px #00ff73, 209px -375.66667px #ffd900, 190px -312.66667px #ff0084, 129px -214.66667px #37ff00, -23px -9.66667px #ff8400, 18px -336.66667px #000dff, -17px -289.66667px #3700ff, -50px 32.33333px #0033ff, 210px -20.66667px #ff2b00, 228px -10.66667px #51ff00, 45px -397.66667px #5500ff, 86px -388.66667px #ffbf00, 175px -269.66667px #d500ff, 234px 43.33333px #00ff15, -170px -96.66667px #0044ff, -55px -267.66667px #5e00ff, -92px -4.66667px #0080ff, 81px -35.66667px #b7ff00, 179px 22.33333px #d900ff, -248px -5.66667px #d5ff00, 210px -328.66667px #ffe600, 114px 13.33333px #6600ff, -19px -195.66667px #00eaff, -228px -78.66667px #9dff00, -112px -386.66667px #ff5100, 73px -59.66667px #3cff00, -66px 48.33333px #b7ff00, -126px -48.66667px #00ff66, -14px -100.66667px #00ff22, 37px -241.66667px #00ff40, 64px -29.66667px #00b7ff, -56px -204.66667px #0026ff, -127px -262.66667px #00ff8c, 10px -143.66667px #fff200, 104px -399.66667px #aa00ff, -155px -42.66667px #00d5ff, 44px -279.66667px #0077ff, 199px -212.66667px #b700ff, 131px -137.66667px #00d5ff; } }

@-o-keyframes bang {
  to {
    box-shadow: 41px 50.33333px #ff00c8, -141px -380.66667px #7bff00, 115px 77.33333px #bbff00, 12px -317.66667px #00ff40, 43px -110.66667px #3cff00, -64px -11.66667px #00ff37, 69px 16.33333px #00ff04, -36px -12.66667px #ff00ea, 235px -403.66667px #00bbff, -40px -368.66667px #8400ff, 159px 12.33333px #1e00ff, 124px -56.66667px #00ffb3, 50px -315.66667px #00ff73, 209px -375.66667px #ffd900, 190px -312.66667px #ff0084, 129px -214.66667px #37ff00, -23px -9.66667px #ff8400, 18px -336.66667px #000dff, -17px -289.66667px #3700ff, -50px 32.33333px #0033ff, 210px -20.66667px #ff2b00, 228px -10.66667px #51ff00, 45px -397.66667px #5500ff, 86px -388.66667px #ffbf00, 175px -269.66667px #d500ff, 234px 43.33333px #00ff15, -170px -96.66667px #0044ff, -55px -267.66667px #5e00ff, -92px -4.66667px #0080ff, 81px -35.66667px #b7ff00, 179px 22.33333px #d900ff, -248px -5.66667px #d5ff00, 210px -328.66667px #ffe600, 114px 13.33333px #6600ff, -19px -195.66667px #00eaff, -228px -78.66667px #9dff00, -112px -386.66667px #ff5100, 73px -59.66667px #3cff00, -66px 48.33333px #b7ff00, -126px -48.66667px #00ff66, -14px -100.66667px #00ff22, 37px -241.66667px #00ff40, 64px -29.66667px #00b7ff, -56px -204.66667px #0026ff, -127px -262.66667px #00ff8c, 10px -143.66667px #fff200, 104px -399.66667px #aa00ff, -155px -42.66667px #00d5ff, 44px -279.66667px #0077ff, 199px -212.66667px #b700ff, 131px -137.66667px #00d5ff; } }

@-ms-keyframes bang {
  to {
    box-shadow: 41px 50.33333px #ff00c8, -141px -380.66667px #7bff00, 115px 77.33333px #bbff00, 12px -317.66667px #00ff40, 43px -110.66667px #3cff00, -64px -11.66667px #00ff37, 69px 16.33333px #00ff04, -36px -12.66667px #ff00ea, 235px -403.66667px #00bbff, -40px -368.66667px #8400ff, 159px 12.33333px #1e00ff, 124px -56.66667px #00ffb3, 50px -315.66667px #00ff73, 209px -375.66667px #ffd900, 190px -312.66667px #ff0084, 129px -214.66667px #37ff00, -23px -9.66667px #ff8400, 18px -336.66667px #000dff, -17px -289.66667px #3700ff, -50px 32.33333px #0033ff, 210px -20.66667px #ff2b00, 228px -10.66667px #51ff00, 45px -397.66667px #5500ff, 86px -388.66667px #ffbf00, 175px -269.66667px #d500ff, 234px 43.33333px #00ff15, -170px -96.66667px #0044ff, -55px -267.66667px #5e00ff, -92px -4.66667px #0080ff, 81px -35.66667px #b7ff00, 179px 22.33333px #d900ff, -248px -5.66667px #d5ff00, 210px -328.66667px #ffe600, 114px 13.33333px #6600ff, -19px -195.66667px #00eaff, -228px -78.66667px #9dff00, -112px -386.66667px #ff5100, 73px -59.66667px #3cff00, -66px 48.33333px #b7ff00, -126px -48.66667px #00ff66, -14px -100.66667px #00ff22, 37px -241.66667px #00ff40, 64px -29.66667px #00b7ff, -56px -204.66667px #0026ff, -127px -262.66667px #00ff8c, 10px -143.66667px #fff200, 104px -399.66667px #aa00ff, -155px -42.66667px #00d5ff, 44px -279.66667px #0077ff, 199px -212.66667px #b700ff, 131px -137.66667px #00d5ff; } }

@keyframes bang {
  to {
    box-shadow: 41px 50.33333px #ff00c8, -141px -380.66667px #7bff00, 115px 77.33333px #bbff00, 12px -317.66667px #00ff40, 43px -110.66667px #3cff00, -64px -11.66667px #00ff37, 69px 16.33333px #00ff04, -36px -12.66667px #ff00ea, 235px -403.66667px #00bbff, -40px -368.66667px #8400ff, 159px 12.33333px #1e00ff, 124px -56.66667px #00ffb3, 50px -315.66667px #00ff73, 209px -375.66667px #ffd900, 190px -312.66667px #ff0084, 129px -214.66667px #37ff00, -23px -9.66667px #ff8400, 18px -336.66667px #000dff, -17px -289.66667px #3700ff, -50px 32.33333px #0033ff, 210px -20.66667px #ff2b00, 228px -10.66667px #51ff00, 45px -397.66667px #5500ff, 86px -388.66667px #ffbf00, 175px -269.66667px #d500ff, 234px 43.33333px #00ff15, -170px -96.66667px #0044ff, -55px -267.66667px #5e00ff, -92px -4.66667px #0080ff, 81px -35.66667px #b7ff00, 179px 22.33333px #d900ff, -248px -5.66667px #d5ff00, 210px -328.66667px #ffe600, 114px 13.33333px #6600ff, -19px -195.66667px #00eaff, -228px -78.66667px #9dff00, -112px -386.66667px #ff5100, 73px -59.66667px #3cff00, -66px 48.33333px #b7ff00, -126px -48.66667px #00ff66, -14px -100.66667px #00ff22, 37px -241.66667px #00ff40, 64px -29.66667px #00b7ff, -56px -204.66667px #0026ff, -127px -262.66667px #00ff8c, 10px -143.66667px #fff200, 104px -399.66667px #aa00ff, -155px -42.66667px #00d5ff, 44px -279.66667px #0077ff, 199px -212.66667px #b700ff, 131px -137.66667px #00d5ff; } }

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0.25; } }

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0.25; } }

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0.25; } }

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0.25; } }

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0.25; } }

@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }
