.assessment-status-component {
  text-align: center; }
  .assessment-status-component .assessment-icon {
    text-align: center;
    display: block;
    position: relative;
    padding: 0 25%; }
    .assessment-status-component .assessment-icon i {
      display: inline-block;
      height: 40px;
      width: 40px;
      color: white;
      line-height: 40px;
      vertical-align: center;
      border: 1px solid lightgrey;
      border-radius: 50vh; }
    .assessment-status-component .assessment-icon .fa-check {
      background-color: #14a214;
      font-size: 25px; }
    .assessment-status-component .assessment-icon .fa-times {
      background-color: #ffa500;
      font-size: 28px; }
    .assessment-status-component .assessment-icon .fa-sync {
      background-color: #3c7a9e;
      font-size: 20px; }
    .assessment-status-component .assessment-icon .fa-double {
      display: block;
      width: auto;
      height: 40px;
      margin: 0 -20px 0 -20px;
      border-radius: 50vh; }
    .assessment-status-component .assessment-icon .fa-triple {
      display: block;
      width: auto;
      height: 40px;
      margin: 0 -50px 0 -50px;
      border-radius: 50vh; }
  .assessment-status-component .assessment-status-container .assessment-status-text {
    text-transform: capitalize;
    display: flex;
    flex: 1;
    justify-content: left;
    align-items: left;
    margin-top: 0.5rem; }
    .assessment-status-component .assessment-status-container .assessment-status-text h6 {
      padding: 0.25rem 0rem 0.25rem 3rem; }
      .assessment-status-component .assessment-status-container .assessment-status-text h6 i {
        width: 25px;
        text-align: center; }
