/***************
 ** Enrollment
 ***************/
.course-switch-description {
  border: 1px solid #4b9379;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 15px 0 30px 0 !important; }
  .course-switch-description i {
    padding-right: 5px; }
  .course-switch-description p {
    font-weight: bold;
    color: #4b9379; }

.course-description {
  padding: 20px 0px 10px 0px; }

.competency-icon {
  text-align: center; }

.competency-description {
  padding: 10px 0; }

.course-switch-tab {
  color: #2196f3 !important; }

.course-switch-indicator {
  background-color: #2196f3 !important; }

.switch-course-button-container {
  text-align: right; }
  .switch-course-button-container .switch-course-button {
    background-color: #4b9379 !important;
    padding: 4px 16px !important;
    margin-top: 10px !important;
    border: 1px solid #4b9379 !important; }
    .switch-course-button-container .switch-course-button:hover {
      opacity: 0.9; }
    .switch-course-button-container .switch-course-button * {
      color: #fff !important; }
    .switch-course-button-container .switch-course-button.in-submission {
      background-color: #fff !important; }
      .switch-course-button-container .switch-course-button.in-submission * {
        color: #4b9379 !important; }
