/***************
 ** SIMA
 ***************/
.sima-outer-container {
  padding: 1rem; }

.sima-container {
  padding: 1rem;
  background: #fff;
  padding-bottom: 10vh; }
  .sima-container .sima-searchbar {
    width: 100%; }
  .sima-container .sima-list-header {
    background-color: #b65fc7;
    border-radius: 10px 10px 0px 0px; }
    .sima-container .sima-list-header h6 {
      color: #fff; }
  .sima-container .sima-right-header .sima-row {
    padding: 0.25rem 0; }
  .sima-container .sima-right-header * button {
    margin-left: 20px;
    text-align: center; }
  .sima-container .sima-right-header .assessment-status-text {
    margin-top: 0px !important; }
  .sima-container .competency-title {
    margin: 1rem 0rem; }
  .sima-container .sima-divider {
    margin: 1rem 0px; }
  .sima-container .sima-files {
    padding-top: 1rem; }
    .sima-container .sima-files .download-chip {
      padding: 0px 0.25rem 0.5rem 0px; }
  .sima-container .sima-review-item {
    border-bottom: 1px solid #d8d8d8; }
    .sima-container .sima-review-item:hover {
      background-color: #ececec; }
