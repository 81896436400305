.attachment-history-modal .table-header {
  border-bottom: 1px solid #a0a0a0; }
  .attachment-history-modal .table-header * {
    font-weight: bold; }

.attachment-history-modal .table-row:nth-child(odd) {
  background: #e8ebec; }

.attachment-history-modal .table-cell {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .attachment-history-modal .table-cell > *:last {
    padding-bottom: 0; }

.attachment-history-modal .history-attachment {
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem; }
  .attachment-history-modal .history-attachment .fal {
    padding-right: 0.25rem;
    font-size: 1.5em; }

.file-selector-dialog .sub-folder-content {
  border-left: 8px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08); }

.file-selector-dialog .open-folder {
  background-color: rgba(0, 0, 0, 0.08); }

.file-selector-dialog .invisible-icon {
  visibility: hidden; }

.file-selector-dialog .checkbox {
  height: auto; }

.file-selector-dialog .selected-file {
  background-color: rgba(0, 0, 0, 0.08); }

.file-selector-dialog .file-search {
  height: 2rem;
  padding: 0.5rem;
  margin: -0.5rem 0;
  font-size: 0.8125rem; }

.assessment-dialog {
  text-align: center; }
  .assessment-dialog .grid-item {
    padding: 7px 0px; }
  .assessment-dialog .problem-title {
    color: #ffa500; }
  .assessment-dialog .reassess-title {
    color: #3c7a9e; }
    .assessment-dialog .reassess-title .icon-circle {
      font-size: 3.5rem; }
  .assessment-dialog .validate-title {
    color: #14a214; }
  .assessment-dialog .icon-circle {
    font-size: 3rem;
    line-height: 4rem; }
  .assessment-dialog .problem-hint {
    border: 1px solid #ffa500;
    background: #f4f4f4;
    padding: 15px 5px;
    border-radius: 5px;
    text-align: left; }
    .assessment-dialog .problem-hint i {
      color: #ffa500;
      padding-right: 10px;
      padding-left: 4px; }
  .assessment-dialog .problem-caption,
  .assessment-dialog .reassess-caption,
  .assessment-dialog .validate-caption {
    opacity: 0.5; }
  .assessment-dialog .confirm-button {
    padding: 4px 16px;
    margin-top: 10px;
    width: 90%; }
    .assessment-dialog .confirm-button * {
      color: #fff; }
  .assessment-dialog .confirm-cancel {
    background-color: #ff0000; }
    .assessment-dialog .confirm-cancel:hover {
      opacity: 0.7;
      background-color: #ff0000; }
  .assessment-dialog .confirm-agree {
    background-color: #14a214; }
    .assessment-dialog .confirm-agree:hover {
      opacity: 0.7;
      background-color: #14a214; }
    .assessment-dialog .confirm-agree.disabled {
      border: 1px solid #14a214;
      background-color: #fff; }
      .assessment-dialog .confirm-agree.disabled span {
        color: #14a214; }

.forgot-password-content {
  min-height: 150px; }
  .forgot-password-content .forgot-password {
    padding-bottom: 25px; }
    .forgot-password-content .forgot-password .form-row {
      margin: 10px 0px; }
    .forgot-password-content .forgot-password .submit-button {
      width: 100%;
      min-height: 54px;
      background-color: #004882;
      color: #fff; }
      .forgot-password-content .forgot-password .submit-button:hover {
        background-color: #023b69; }
      .forgot-password-content .forgot-password .submit-button:active {
        background-color: #012542; }
