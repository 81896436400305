@keyframes fadein {
  0% {
    height: 0px;
    opacity: 0; }
  50% {
    height: auto;
    opacity: 0; }
  100% {
    height: auto;
    opacity: 1; } }

@keyframes fadeout {
  0% {
    height: auto;
    opacity: 1; }
  50% {
    height: auto;
    opacity: 0; }
  100% {
    height: 0px;
    opacity: 0; } }

.toast-container {
  position: absolute;
  top: 0;
  left: 10%;
  right: 10%;
  z-index: 9001; }
  .toast-container .fade-in {
    max-height: 55px;
    height: auto;
    opacity: 1;
    animation-name: fadein;
    animation-duration: 500ms; }
  .toast-container .fade-out {
    transition: max-height 300ms 200ms, opacity 500ms, margin-bottom 300ms 250ms;
    max-height: 0px;
    margin-bottom: -5px;
    opacity: 0; }
  .toast-container .toast {
    background-color: #00c3df;
    color: white;
    text-align: center;
    margin-bottom: 0.25rem; }
    .toast-container .toast.success {
      background-color: #9dcb42; }
    .toast-container .toast.error {
      background-color: #f4473d; }
    .toast-container .toast.warning {
      background-color: #ff9312; }
    .toast-container .toast.info {
      background-color: #00c3df; }
    .toast-container .toast .toast-body {
      display: inline-flex;
      align-items: center;
      width: 100%;
      padding: 0.7rem;
      max-width: 1100px; }
      .toast-container .toast .toast-body .toast-icon {
        font-size: 25px;
        padding: 0 0.25rem;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .toast-container .toast .toast-body .toast-message {
        flex: 1;
        text-align: left;
        font-weight: 400; }
      .toast-container .toast .toast-body .toast-dismiss {
        font-size: 15px;
        padding: 0 0.25rem;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .toast-container .toast .toast-body .toast-dismiss i {
          cursor: pointer; }
    .toast-container .toast .toast-progress-bar {
      height: 5px;
      background-color: #c2c7e7; }
      .toast-container .toast .toast-progress-bar .toast-progress-indicator {
        height: 5px;
        width: 100%;
        background-color: #3748ac; }
