/***************
 ** User
 ***************/
.register {
  height: 100%; }
  .register .register-box {
    padding: 40px;
    background-color: #fff;
    max-width: 500px; }
  .register .logo-container {
    text-align: center; }
  .register .form-subtitle {
    text-align: center; }
  .register .legacy-subtitle {
    text-align: center; }
  .register .legacy-email {
    text-align: center;
    padding: 20px 0px; }
  .register .submit-button {
    width: 100%;
    background-color: #004882;
    color: #fff; }
    .register .submit-button:hover {
      background-color: #023b69; }
    .register .submit-button:active {
      background-color: #012542; }
  .register .support-link {
    color: #006dc3;
    padding: 0px;
    margin: 0px;
    font-size: 15px !important;
    text-align: center; }
  .register .form-item-container {
    margin: 10px 0px; }
    .register .form-item-container .form-item {
      min-height: 45px; }
      .register .form-item-container .form-item input:disabled {
        cursor: not-allowed;
        background-color: #eeeeee; }
