.role-pucks {
  text-align: center; }
  .role-pucks .puck {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1px 0 0 0;
    font-weight: bold;
    color: white;
    background-color: darkgrey;
    height: 32px;
    width: 32px;
    border: 2px solid #ffffff;
    border-radius: 50vh; }
  .role-pucks .double-puck {
    width: 64px; }
  .role-pucks .st-pucks .puck {
    background-color: #eed69c; }
    .role-pucks .st-pucks .puck:last-child {
      background-color: #d59b1e; }
  .role-pucks .cl-pucks .puck {
    background-color: #c5c58e; }
    .role-pucks .cl-pucks .puck:last-child {
      background-color: #747401; }
  .role-pucks .af-pucks .puck {
    background-color: #e3b2b3; }
    .role-pucks .af-pucks .puck:last-child {
      background-color: #a91e1f; }
  .role-pucks .puck-stack .puck {
    margin: 0.2rem -20px 0.2rem 0; }
    .role-pucks .puck-stack .puck:last-child {
      margin-left: 0.2rem; }
    .role-pucks .puck-stack .puck:last-child {
      margin-right: 0.2rem; }
  .role-pucks .puck-stack .double-puck {
    margin: 0.2rem -50px 0.2rem 0; }
    .role-pucks .puck-stack .double-puck:last-child {
      margin-left: 0.5rem; }
    .role-pucks .puck-stack .double-puck:last-child {
      margin-right: 0.5rem; }
