/***************
 ** Vendors
 ***************/
.vendor-import-button {
  border: 1px solid #b7b7b7 !important;
  background-color: #ececec !important;
  padding: 20px; }
  .vendor-import-button:hover {
    background-color: #d8d8d8 !important; }
  .vendor-import-button:active {
    background-color: #bdbdbd !important; }
  .vendor-import-button img {
    width: 30px;
    padding-top: 7px;
    margin-right: 10px; }
  .vendor-import-button * {
    color: #222; }

.picker-dialog-bg {
  z-index: 1400 !important; }

.picker-dialog {
  z-index: 1401 !important; }
