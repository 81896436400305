/***************
 ** Organizations
 ***************/
.organizations-wrapper {
  padding: 1rem; }

.organizations {
  padding: 1rem;
  background: #fff; }
  .organizations .search-divider {
    margin-top: 20px; }
  .organizations .organization-tabs {
    margin: 20px 0 0 0; }
  .organizations .list-actions {
    padding: 0px 0px 10px 10px;
    text-align: right; }
    .organizations .list-actions .checkbox {
      display: block;
      padding-top: 10px; }
  .organizations .organization-list {
    margin-top: 30px;
    border-radius: 10px 10px 0px 0px; }
  .organizations .back-link {
    color: #3c7a9e; }
  .organizations .organization-header {
    background-color: #5490c4;
    border-radius: 10px 10px 0px 0px; }
    .organizations .organization-header h6 {
      color: #fff; }
  .organizations .organization-empty {
    background-color: #fff;
    border-bottom: 1px solid #c9c9c9; }
    .organizations .organization-empty p {
      color: #000;
      font-weight: bold;
      font-size: 16px;
      padding: 5px; }
  .organizations .organization-item {
    background-color: #fff;
    border-bottom: 1px solid #c9c9c9; }
    .organizations .organization-item p {
      display: inline-flex;
      color: #4b7293; }
    .organizations .organization-item .student p,
    .organizations .organization-item .status p,
    .organizations .organization-item .local-mentor p,
    .organizations .organization-item .associate-faculty p,
    .organizations .organization-item .mentor p {
      color: #484848; }
    .organizations .organization-item .status p {
      cursor: pointer;
      font-size: 14px; }
    .organizations .organization-item .status p i {
      font-size: 12px;
      cursor: pointer;
      margin-right: 5px;
      margin-top: 3px; }
    .organizations .organization-item .status .green {
      color: #14a214; }
    .organizations .organization-item .status .red {
      color: #ff0000; }
    .organizations .organization-item .enrollments .link {
      color: #5490c4; }
    .organizations .organization-item .enrollments .link:hover {
      color: #5490c4;
      text-decoration: 1px solid #5490c4; }
    .organizations .organization-item .badge-grid {
      width: 20px; }
  .organizations .organization-item:hover {
    background-color: #f5f5f5; }
  .organizations .header-badge span {
    top: 0;
    left: 0;
    margin-left: 0.5rem; }
  .organizations .view-course-button {
    background-color: #138c21; }
    .organizations .view-course-button * {
      color: #fff; }
  .organizations .view-course-button:hover {
    background-color: #17a928 !important; }
