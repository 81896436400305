/***************
 ** Enrollment
 ***************/
.enrollment {
  height: 100%;
  overflow: visible; }
  .enrollment .header,
  .enrollment .main-divider {
    margin-bottom: 1rem; }
  .enrollment .enrollment-course-group {
    padding: 1rem;
    margin-bottom: 1rem; }
    .enrollment .enrollment-course-group:last-child {
      margin-bottom: 0; }
  .enrollment .course-overview-container {
    border: 1px solid grey;
    border-radius: 2px 2px 4px 4px; }
  .enrollment .group-icon-container {
    display: flex;
    align-items: center; }
  .enrollment .course-competency-circles {
    display: flex;
    align-items: center; }
    .enrollment .course-competency-circles .fa-circle {
      margin-right: 0.25rem; }
  .enrollment .course-overview-body-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; }
  .enrollment .course-overview-row {
    flex: 0 1 auto; }
  .enrollment .course-overview-detail-row {
    -webkit-flex: 1 1 0 !important;
    /* important for Safari */
    flex: 0 1 auto; }
  .enrollment .course-overview-add-course {
    border: 2px dashed #c7c9ca;
    border-radius: 5px;
    cursor: pointer;
    min-height: 300px;
    text-align: center;
    box-shadow: none; }
    .enrollment .course-overview-add-course:hover {
      opacity: 0.5; }
    .enrollment .course-overview-add-course * {
      color: #b3b3b3; }
    .enrollment .course-overview-add-course h1 {
      font-size: 5rem; }
  .enrollment .course-overview-button {
    color: white;
    padding: 0.25rem 1rem;
    margin: 10px 0; }
    .enrollment .course-overview-button:hover {
      text-decoration: none; }
  .enrollment .course-switch {
    text-align: right; }
  .enrollment .course-switch-button {
    background: #fff;
    padding: 0.25rem 1rem;
    margin: 10px 0; }
    .enrollment .course-switch-button i {
      padding-right: 5px; }
  .enrollment .competency-list-item {
    padding: 0.75rem; }
    .enrollment .competency-list-item:last-child {
      border-bottom: none;
      border-radius: 0 0 3px 3px; }
    .enrollment .competency-list-item:nth-child(even) {
      background: #f5f5f5; }

.course-switch-dialog .course-switch-title {
  padding-top: 50px;
  padding-bottom: 0px; }

.course-switch-dialog .close-button {
  text-align: right; }
  .course-switch-dialog .close-button i {
    font-size: 20px;
    color: #9f9f9f; }

.course-switch-dialog .course-item:hover {
  cursor: pointer;
  opacity: 0.9;
  background: #eaeaea; }

.course-switch-dialog .course-item.selected {
  background: #eaeaea; }

.course-switch-dialog .list-icon-wrapper {
  text-align: center; }

.course-switch-dialog .list-icon {
  color: #fff;
  padding: 15px;
  border-radius: 3px; }
