@media print {
  html,
  body,
  #root {
    overflow: visible !important;
    font-size: 13px !important;
    padding: 10px !important; }
  .enrollment-statement-report {
    padding: 0px !important;
    background-color: #FFF; }
  .section-row {
    page-break-inside: avoid !important; }
  .print-50 {
    max-width: 49% !important;
    flex-basis: 49% !important; }
  .print-report-wrapper,
  header,
  .MuiDrawer-root {
    display: none !important; } }

.print-report-wrapper {
  text-align: center;
  margin: 20px 0px !important; }

.print-report-button {
  background: #14aaf5 !important;
  padding: 10px 20px;
  margin: 10px;
  text-align: center; }
  .print-report-button p {
    color: #fff;
    font-weight: bold; }
  .print-report-button:hover {
    background: #008ce3 !important; }

.statement-header {
  margin-bottom: 20px; }

.address-summary {
  border: 1px solid #000; }

.student-summary {
  text-align: right; }
  .student-summary .student-summary-title {
    background-color: #8e0034;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding: 0 5px; }

.last-payment {
  border: 1px solid #000;
  padding: 5px; }

.enrollment-statement-report {
  padding: 10px;
  background-color: #FFF; }

.section-title {
  text-align: center;
  font-weight: bold;
  padding: 5px;
  background: #fff;
  margin-top: 30px !important; }

.section-header {
  background-color: #d9d9d9;
  padding: 5px 5px;
  font-weight: bold; }

.section-row {
  border-left: solid 2px #d9d9d9;
  border-right: solid 2px #d9d9d9;
  border-bottom: 1px dotted #ccc;
  padding: 2px 0px; }

.print-button {
  border: 1px solid lightBlue;
  padding: 5px;
  width: 150px;
  float: right;
  text-align: center;
  display: block; }
